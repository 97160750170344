/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import tfbStoreTitle from '../../icons/tfb-store-title.svg'
import tfbStoreClose from '../../icons/tfb-store-close.svg'
import styles from './styles/StorePopupModal.module.css'
import { PAMMState } from '../../redux/reducers/rootReducer';

const StorePopupModal = () => {

  const redirectButton: string | undefined = useSelector((state: PAMMState) => state.configReducer?.tfbStorePopupModal?.redirectButton);

  const modalRef = React.useRef(null);

  const showTfbStorePopupModal: boolean = useSelector((state: PAMMState) => state.configReducer.tfbStorePopupModal.showTfbStorePopupModal);

  const [isOpen, setIsOpen] = React.useState(showTfbStorePopupModal);

  const closeModal = () => {
    window.sessionStorage.setItem('closedStorePopupModal', 'closed')
    setIsOpen(false);
  };

  const handleRedirect = (url: string) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
      closeModal();
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !((modalRef.current as unknown) as HTMLElement).contains(event.target as Node)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {isOpen && (
        <div 
          className={styles['modal-overlay']}
          ref={modalRef}
        >
          <div 
            className={styles['modal-content']}
          >
            <div className='d-flex justify-content-between'>
              <img src={tfbStoreTitle} alt="store-title" />
              <img src={tfbStoreClose} alt="store-close" onClick={closeModal}/>
            </div>
            <div className={styles['custom-title']}>
              <FormattedMessage id="admin.storePopupModal.title"/>
            </div>
            <div className={styles['custom-body']}>
              <FormattedMessage id="admin.storePopupModal.body"/>
            </div>
            <button
              className={styles['explore-button']}
              onClick={() => handleRedirect(redirectButton)}
            >
              <FormattedMessage id="admin.storePopupModal.button"/>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StorePopupModal;
